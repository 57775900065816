import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Our Services | Наші послуги у Творчих Петлях
			</title>
			<meta name={"description"} content={"Розкривайте власні таланти – Відкрийте для себе різноманіття наших курсів у Творчих Петлях"} />
			<meta property={"og:title"} content={"Our Services | Наші послуги у Творчих Петлях"} />
			<meta property={"og:description"} content={"Розкривайте власні таланти – Відкрийте для себе різноманіття наших курсів у Творчих Петлях"} />
			<meta property={"og:image"} content={"https://nulvorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nulvorich.com/img/4634647.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nulvorich.com/img/4634647.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nulvorich.com/img/4634647.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Огляд послуг
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--darkL2"
					width="90%"
					lg-width="100%"
					sm-text-align="left"
				>
					У Творчих Петлях ми пропонуємо широкий спектр послуг, спрямованих на розвиток вашої творчості та майстерності в рукоділлі. Від основних курсів в'язання та вишивання до складних майстер-класів, ми допоможемо вам знайти та розвинути свої таланти.
				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://nulvorich.com/img/8.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-light"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Курси в'язання
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Основи в'язання: Навчіться основ в'язання – від простих петель до створення перших виробів.
					<br/>
Продвинуті техніки: Для тих, хто готовий піднятися на новий рівень, ми пропонуємо курси з роботи з різними видами пряжі та в'язальних візерунків.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Курси вишивання
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Основи вишивки: Вивчіть ази вишивки, починаючи з простих стібків та переходячи до складніших візерунків.
					<br/>
Спеціалізовані техніки: Поглиблені курси з вишивки бісером, стрічками та іншими нестандартними матеріалами.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Майстер-класи з рукоділля
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Унікальні проєкти: Приєднуйтесь до спеціальних майстер-класів, де ви можете створити щось унікальне, наприклад, декоративні подушки, сумки або аксесуари для дому.
					<br/>
Сезонні теми: Освоюйте тематичні проєкти, актуальні для різних часів року, що додасть вашому дому особливого затишку.
				</Text>
			</Box>
		</Section>
		<Section padding="50px 0 80px 0" md-padding="80px 0 80px 0" sm-padding="50px 0 50px 0" quarkly-title="CTA/Lead/Form-3">
			<Box
				min-width="100px"
				min-height="100px"
				padding="80px 80px 80px 80px"
				border-radius="40px"
				md-padding="50px 50px 50px 50px"
				sm-padding="50px 30px 50px 30px"
				background="--color-dark"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--light"
					text-align="center"
					font="normal 700 38px/1.2 --fontFamily-sans"
					lg-margin="0px 0 20px 0"
					sm-font="normal 700 36px/1.2 --fontFamily-sans"
					sm-text-align="left"
				>
					Створіть свою історію успіху разом з нами
				</Text>
				<Text
					margin="0px 40px 50px 40px"
					text-align="center"
					font="--lead"
					color="rgba(255, 255, 255, 0.8)"
					lg-margin="0px 0 50px 0"
					sm-text-align="left"
				>
					У Творчих Петлях ви не просто навчаєтесь рукоділля – ви стаєте частиною спільноти, де кожен має можливість розкрити свій талант та втілити унікальні ідеї в життя. З нами ви зможете не лише вдосконалити свої навички, а й поділитися своєю творчістю зі світом. Творчі Петлі надають всі необхідні інструменти та підтримку для того, щоб ваше хобі перетворилося на справжнє мистецтво.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					justify-content="center"
					sm-flex-direction="column"
					sm-align-items="center"
				>
					<Button
						background="rgba(247, 251, 255, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						font="normal 400 17px/1.5 --fontFamily-sans"
					>
						Зв'яжіться з нами &gt;&gt;
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});